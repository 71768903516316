import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '255, 202, 87',
		'primary-dark': '29, 49, 103',
		'accent': '221, 42, 27',
		'accent-plus': '255, 255, 255',
	},
});
